import { makeStyles, Theme } from '@material-ui/core/styles'

import { colors } from '~/styles/theme'

interface IProps {
  open: boolean
}
const drawerWidth = 220
const closedDrawerWidth = 60

const style = makeStyles<Theme, IProps>((theme) => ({
  drawerBody: {
    padding: theme.spacing(0, 1),
    paddingBottom: 50,
    [theme.breakpoints.down('sm')]: {
      height: '65vh',
      overflowY: 'auto',
      paddingBottom: 10,
    },
    [theme.breakpoints.up('md')]: {
      height: '65vh',
      overflowY: 'auto',
      marginBottom: 150,
    },
  },
  listItem: {
    minHeight: 38,
    justifyContent: ({ open }) => (open ? 'initial' : 'center'),
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: colors.Concrete,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '6px',
      color: colors.White,
      justifyContent: 'center',
    },
  },
  listItemIcon: {
    minWidth: 0,
    marginRight: ({ open }) => (open ? theme.spacing(3) : 0),
    marginTop: theme.spacing(1),
    justifyContent: 'center',
    color: 'inherit',
  },
  listItemText: {
    display: ({ open }) => (open ? 'block' : 'none'),
    fontSize: theme.fontSize[16],
  },
  itemText: {
    fontSize: theme.fontSize[16],
  },
  itemTextSm: {
    fontSize: theme.fontSize[14],
  },
  activeItem: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '6px',
    color: colors.White,
    justifyContent: 'center',
  },
  drawerFoot: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: ({ open }) => (open ? 'flex-start' : 'center'),
    padding: theme.spacing(0, 1),
    position: 'fixed',
    width: ({ open }) => (open ? drawerWidth : closedDrawerWidth),
    [theme.breakpoints.down('md')]: {
      height: 145,
    },
  },
}))

export default style
