import React, { memo } from 'react'

import {
  ListItemIcon,
  Box,
  ListItem,
  List,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import classnames from 'classnames'
import { ReactSVG } from 'react-svg'

import { currentSubdomain, SUBDOMAINS } from '~/config'
import useScreenMobile from '~/utils/hooks/useScreenMobile'

import useCustom from './hooks'
import useStyles from './style'

interface Props {
  open: boolean
}

const Bottom = ({ open }: Props) => {
  const classes = useStyles({ open })
  const {
    anchorEl,
    profileName,
    handleDirectProfile,
    handleLogout,
    timezone,
    handleClick,
    handleClose,
    handleChangeTimeZone,
  } = useCustom()
  const isMobile = useScreenMobile()
  const theme = useTheme()

  const timezones = Array.from({ length: 3 }, (_, i) => {
    const value = i + 7 // Nilai dari 7 sampai 9
    return {
      label: `GMT+${value}`,
      value: value,
    }
  })

  return (
    <>
      <Box bgcolor={theme.palette.primary.main} mr={1}>
        <List>
          <>
            <ListItem
              className={classes.listItem}
              button
              disableRipple
              onClick={handleClick}
            >
              <ListItemIcon
                className={`${classes.listItemIcon} ${classes.listItemConfigIcon}`}
              >
                <ReactSVG
                  className="svg-icon"
                  src="/public/icons/menu-icon/test-histroy.svg"
                />
              </ListItemIcon>
              <ListItemText
                className={classnames({ [classes.listItemText]: !isMobile })}
              >
                <div className={classes.itemText}>
                  GMT{timezone >= 0 ? `+${timezone}` : timezone}
                </div>
              </ListItemText>
            </ListItem>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {timezones.map((item) => (
                <MenuItem
                  key={item.value}
                  onClick={handleChangeTimeZone(item.value)}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </>
          <ListItem
            className={classes.listItem}
            button
            disableRipple
            onClick={handleDirectProfile}
          >
            <ListItemIcon
              className={`${classes.listItemIcon} ${classes.listItemConfigIcon}`}
            >
              <ReactSVG
                className="svg-icon"
                src="/public/icons/menu-icon/profile.svg"
              />
            </ListItemIcon>
            <ListItemText
              className={classnames({ [classes.listItemText]: !isMobile })}
            >
              <div className={classes.itemText}>{profileName}</div>
            </ListItemText>
          </ListItem>
          <ListItem
            className={classes.listItem}
            onClick={handleLogout}
            button
            disableRipple
          >
            <ListItemIcon
              className={`${classes.listItemIcon} ${classes.listItemConfigIcon}`}
            >
              <ReactSVG
                className="svg-icon"
                src="/public/icons/menu-icon/logout.svg"
              />
            </ListItemIcon>
            <ListItemText
              className={classnames({ [classes.listItemText]: !isMobile })}
            >
              <div className={classes.itemText}>Logout</div>
            </ListItemText>
          </ListItem>
        </List>
        {currentSubdomain !== SUBDOMAINS.FLEETO_XL_SUBDOMAIN && (
          <Box
            width="100%"
            alignItems="center"
            display="flex"
            className={classes.footerTitle}
          >
            <Box hidden={!open} mr={2}>
              Powered by
            </Box>
            <Box>
              <ReactSVG src="/public/brands/logo-xapiens.svg" />
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default memo(Bottom)
