import produce from 'immer'
import { create } from 'zustand'

interface TimezoneState {
  timezone: number // Menyimpan offset timezone
  setTimezone: (timezone: number) => void // Fungsi untuk mengubah timezone
}

export const useTimezoneStore = create<TimezoneState>((set) => ({
  timezone: 7, // Default timezone (WIB / UTC+7)
  setTimezone: (timezone) =>
    set(
      produce((state: TimezoneState) => {
        state.timezone = timezone // Mengubah timezone dalam state
      })
    ),
}))
