import React, { memo } from 'react'

import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import classnames from 'classnames'

import useAuth from '~/utils/auth/useAuth'
import useScreenMobile from '~/utils/hooks/useScreenMobile'

import Bottom from './Bottom'
import useStyles from './style'

interface Props {
  currentPath: string
  firstPath: string
  isPermissionGranted: (permission: string) => boolean
  open: boolean
  pushHistory: (path: string) => void
  routes: any[]
}

const MenuSidebar = ({
  currentPath,
  firstPath,
  isPermissionGranted,
  open,
  pushHistory,
  routes,
}: Props) => {
  const classes = useStyles({ open })
  const isMobile = useScreenMobile()

  const { userData } = useAuth()

  const orgCode = userData?.userOrg?.orgCode?.toLowerCase()

  return (
    <>
      <List className={classes.drawerBody}>
        {routes.map(
          ({ id, title, icon, path, defaultPath, permission, dynamicIcon }) => {
            const permitted = isPermissionGranted(permission)

            if (!permitted) return null

            return (
              <ListItem
                onClick={() => {
                  if (defaultPath) {
                    pushHistory(defaultPath)
                  } else {
                    pushHistory(path)
                  }
                }}
                key={id}
                button
                disableRipple
                className={classnames(classes.listItem, {
                  [classes.activeItem]:
                    currentPath === path || firstPath === id,
                })}
              >
                {dynamicIcon ? (
                  <ListItemIcon className={classes.listItemIcon}>
                    {dynamicIcon?.[orgCode] || icon}
                  </ListItemIcon>
                ) : (
                  <ListItemIcon className={classes.listItemIcon}>
                    {icon}
                  </ListItemIcon>
                )}

                <ListItemText
                  className={classnames({ [classes.listItemText]: !isMobile })}
                >
                  <div
                    className={classnames({
                      [classes.itemText]: title.length < 20,
                      [classes.itemTextSm]: title.length >= 20,
                    })}
                  >
                    {title}
                  </div>
                </ListItemText>
              </ListItem>
            )
          }
        )}
      </List>
      <div className={classes.drawerFoot}>
        <Bottom open={open} />
      </div>
    </>
  )
}

export default memo(MenuSidebar)
