import { useCallback, useEffect, useMemo, useState } from 'react'

import { redirectIdmHost } from '~/config'
import { useTimezoneStore } from '~/store/timezone'
import useAuth from '~/utils/auth/useAuth'

const useCustom = () => {
  const { userData, handleLogout } = useAuth()
  const handleDirectProfile = () => {
    window.location.href = `${redirectIdmHost}/settings/profile`
  }

  const orgCode = userData?.userOrg?.orgCode.toLowerCase()
  const { timezone, setTimezone } = useTimezoneStore()

  useEffect(() => {
    if (orgCode === 'xapiens') {
      setTimezone(8)
    } else {
      setTimezone(7)
    }
  }, [orgCode])

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeTimeZone = useCallback(
    (thisTimezone) => () => {
      setTimezone(thisTimezone)
      handleClose()
    },
    []
  )

  const profileName = useMemo(() => userData?.userName || '', [userData])

  return {
    anchorEl,
    handleChangeTimeZone,
    handleClick,
    handleClose,
    handleDirectProfile,
    handleLogout,
    profileName,
    timezone,
  }
}

export default useCustom
