const blue = {
  50: '#419BD8',
  60: '#267FBA',
  80: '#1F6898',
  90: '#0b4fa2',
  100: '#92c5e9',
  200: '#1b6bb9',
  500: '#031a43',
}

const black = {
  5: '#F5F5F5',
  60: '#A0A4A8',
  100: '#F8F8F8',
  200: '#E5E5E5',
  300: '#C4C4C4',
  400: '#9B9B9B',
  500: '#5B5B5B',
  600: '#3C3C3C',
  700: '#2B2B29',
  800: '#252524',
  900: '#1D1D1B',
  A100: '#F2F2F8',
  A200: '#D6D8E7',
  A300: '#C9CCE1',
  A900: '#000000',
}

const grey = {
  10: '#F9F9F9',
  5: '#EAEAEA',
}

export const colors = {
  LightSilver: '#D9D9D9',
  AlizarinCrimson: '#e02020',
  TorchRed: '#F5222D',
  Tamarillo: '#9F161D',
  FireEngineRed: '#ff0003',
  Chablis: '#fff4f4',
  Sun: '#FAAD14',
  LemonChrome: '#ffc303',
  EarlyDawn: '#FFFBE6',
  SeashellPeach: '#FFF1E8',
  Conifer: '#7FE84A',
  Gossip: '#D3FCC5',
  PigPink: '#FEDDE0',
  Malachite: '#08c06c',
  HintOfGreen: '#F2FFF2',
  SugarCane: '#F6FFED',
  TropicalRainForest: '#00684f',
  PaleBlue: '#cef7ff',
  Malibu: '#75BDFF',
  Turquoise: '#3ACFBE',
  AliceBlue: '#f7fbff',
  LilyWhite: '#eefaff',
  Solitude: '#e5f1ff',
  HavelockBlue: '#419BD8',
  Astral: '#397EAD',
  ScienceBlue: '#006ebe',
  WildSand: '#f5f5f5',
  Gallery: '#eeeeee',
  Mercury: '#e8e8e8',
  Alto: '#d5d5d5',
  Nobel: '#b7b7b7',
  DustyGrey: '#9b9b9b',
  AthensGray: '#EEF0F4',
  GrayChateau: '#A0A4A8',
  RiverBed: '#424b5f',
  Black: '#000000',
  SpanishBlue: '#006CB9',
  Lima: '#52C41A',
  Ecstasy: '#F58025',
  Charcoal: '#444444',
  DarkNavy: '#282560',
  Boulder: '#7a7a7a',
  GreyArea: '#dddddd',
  PattensBlue: '#dae9ff',
  DodgerBlue2: '#1890FF',
  GreenHaze: '#059957',
  CaribbeanGreen: '#07BDA8',
  GulfBlue: '#052257',
  Gossamer: '#33A792',
  PineGreen: '#04786B',
  Peppermint: '#D2F0D2',
  FringyFlower: '#A8DEBB',
  Salem: '#05974B',
  Denim2: '#1952BB',
  Lochinvar: '#349180',
  PigeonPost: '#A8BBDE',
  White: '#FFFFFF',
  Concrete: '#F2F2F2',
  Gray: '#8b8b8b',
  ChathamsBlue: '#193B79',
  Atoll: '#075D68',
  FrenchGray: '#B9BEC8',
  Matisse: '#1a8598',
  Silver: '#cbcaca',
  blue,
  black,
  grey,
}
