import React from 'react'

import Visibility from '@material-ui/icons/Visibility'
import { ReactSVG } from 'react-svg'

import { DevicesFilterType, QcFilterType } from '~/gqlcodegen/fms/types'

import { idleIcon, offlineIcon, onlineIcon } from './iconMap'

import { DOMAINS, SUBDOMAINS } from '.'

export const AUTOHIDE_DURATION_SNACKBAR = 5000

export const UNAUTHORIZED = 'Unauthorized'

export const LOCAL_STORAGE_KEY_USER = 'USER_DATA'

export const PAGINATION_RESULT_DEFAULT = {
  current: 1,
  totalPage: 1,
}

export const MAPPING_COMMANDS_TYPE = {
  SUPERSOCO_LOCK: 'SUPERSOCO_LOCK',
  SUPERSOCO_UNLOCK: 'SUPERSOCO_UNLOCK',
  SUPERSOCO_POWERON: 'SUPERSOCO_POWERON',
  SUPERSOCO_OPENSEAT: 'SUPERSOCO_OPENSEAT',
  CAN_ON: 'setdigout 1?',
  CAN_OFF: 'setdigout 0?',
}

export const SNACKBAR_OPTIONS = {
  DURATION: 10000,
  SIZE: 'md',
}

export const RESPONSE_MESSAGE = {
  CREATED_OK: 'Data Saved Successfully',
  UPDATED_OK: 'Data Updated Successfully',
  DELETED_OK: 'Data Deleted Successfully',
  ASSIGN_OK: 'Data Assigned Successfully',
  UNASSIGN_OK: 'Data Unassigned Successfully',
  DEFAULT: 'Something went wrong',
}

export const PAGINATION_OPTIONS = [
  {
    value: 5,
    label: '5/page',
  },
  {
    value: 10,
    label: '10/page',
  },
  {
    value: 15,
    label: '15/page',
  },
]

export const VEHICLE_STATUS = {
  STOP: 'stop',
  MOVING: 'moving',
  IDLE: 'idling',
  NA: 'n/a',
}

export const VEHICLE_STATUS_LABEL_MAPPING = {
  [VEHICLE_STATUS.IDLE]: 'Idle',
  [VEHICLE_STATUS.MOVING]: 'Moving',
  [VEHICLE_STATUS.STOP]: 'Stop',
  [VEHICLE_STATUS.NA]: 'N/A',
}

export const DEVICES_FILTER_BY = {
  ALL: DevicesFilterType.None,
  ASSIGNED: DevicesFilterType.Assigned,
  UNASSIGNED: DevicesFilterType.Unassigned,
}

export const QC_FILTER = {
  ALL: QcFilterType.None,
  PASS: QcFilterType.Pass,
  FAIL: QcFilterType.Fail,
  NOT_TESTED: 'NOT_TESTED',
}

export const QC_STATUS_LABEL = {
  [QC_FILTER.NOT_TESTED]: 'Not Tested',
  [QC_FILTER.PASS]: 'QC Passed',
  [QC_FILTER.FAIL]: 'QC Failed',
}

export const QC_STATUS_COLOR = {
  [QC_STATUS_LABEL[QC_FILTER.FAIL]]: 'danger',
  [QC_STATUS_LABEL[QC_FILTER.PASS]]: 'success',
  [QC_STATUS_LABEL[QC_FILTER.NOT_TESTED]]: 'default',
}

export const ASSIGNED_STATUS_COLOR = {
  [DevicesFilterType.Assigned]: 'success',
  [DevicesFilterType.Unassigned]: 'danger',
}

export const QC_ICON = {
  [QC_STATUS_LABEL[QC_FILTER.FAIL]]: '',
  [QC_STATUS_LABEL[QC_FILTER.PASS]]: <Visibility className="responsive-icon" />,
  [QC_STATUS_LABEL[QC_FILTER.NOT_TESTED]]: '',
}

export const indonesiaPolygonCoordinate = [
  [
    [94.7717121989, -11.2085667762],
    [141.0200344026, -11.2085667762],
    [141.0200344026, 6.2744498481],
    [94.7717121989, 6.2744498481],
    [94.7717121989, -11.2085667762],
  ],
]

export const manufacturingArea = [
  [
    [107.163, -6.3405],
    [107.163, -6.3605],
    [107.183, -6.3605],
    [107.183, -6.3405],
    [107.163, -6.3405],
  ],
]

export const areaAEC = [
  [
    [106.799, -6.213],
    [106.799, -6.233],
    [106.819, -6.233],
    [106.819, -6.213],
    [106.799, -6.213],
  ],
]

export const ROW_SIZE = 70

export const PERMISSIONS = {
  FMS_READ_HISTORICAL: 'FMS_READ_HISTORICAL',
  FMS_REPORT_KALISTA: 'FMS_REPORT_KALISTA',
  FMS_READ_PRODUCTIVITY: 'FMS_READ_PRODUCTIVITY',
  FMS_READ_DASHBOARD: 'FMS_READ_DASHBOARD',
  FMS_ON_OFF_COMMAND: 'FMS_ON_OFF_COMMAND',
  FMS_READ_ALERT: 'FMS_READ_ALERT',
  FMS_READ_DEVICE: 'FMS_READ_DEVICE',
  FMS_READ_DRIVER: 'FMS_READ_DRIVER',
  FMS_READ_GEOFENCE: 'FMS_READ_GEOFENCE',
  FMS_READ_IOT_CHECK_AFTER_SALES: 'FMS_READ_IOT_CHECK_AFTER_SALES',
  FMS_READ_IOT: 'FMS_READ_IOT',
  FMS_READ_LIVETRACK: 'FMS_READ_LIVETRACK',
  FMS_READ_MANUFACTURE: 'FMS_READ_MANUFACTURE',
  FMS_READ_PREDELIVERY_ASSETS: 'FMS_READ_PREDELIVERY_ASSETS',
  FMS_READ_PREDELIVERY_TRACKING: 'FMS_READ_PREDELIVERY_TRACKING',
  FMS_READ_REPORT: 'FMS_READ_REPORT',
  FMS_READ_REPORT_GROUP: 'FMS_READ_REPORT_GROUP',
  FMS_READ_COMMAND_ACCESS_HISTORY: 'FMS_READ_COMMAND_ACCESS_HISTORY',
  FMS_READ_SUBSCRIPTIONS: 'FMS_READ_SUBSCRIPTIONS',
  FMS_READ_TEST_RECORD: 'FMS_READ_TEST_RECORD',
  FMS_READ_VEHICLE: 'FMS_READ_VEHICLE',
  FMS_READ_ROUTES: 'FMS_READ_ROUTES',
  FMS_ASSET_PLUS: 'FMS_ASSET_PLUS',
  FMS_READ_ASSET_BIKE: 'FMS_READ_ASSET_BIKE',
  FMS_READ_ASSET_CAR: 'FMS_READ_ASSET_CAR',
  FMS_TRACKING_PLUS: 'FMS_TRACKING_PLUS',
  FMS_SHOW_MAP: 'FMS_SHOW_MAP',
  FMS_WRITE_COMMAND: 'FMS_WRITE_COMMAND',
  FMS_WRITE_DEVICE: 'FMS_WRITE_DEVICE',
  FMS_WRITE_DRIVER: 'FMS_WRITE_DRIVER',
  FMS_WRITE_GEOFENCE: 'FMS_WRITE_GEOFENCE',
  FMS_WRITE_IOT: 'FMS_WRITE_IOT',
  FMS_WRITE_MANUFACTURE: 'FMS_WRITE_MANUFACTURE',
  FMS_WRITE_SUBSCRIPTIONS: 'FMS_WRITE_SUBSCRIPTIONS',
  FMS_WRITE_TEST_RECORD: 'FMS_WRITE_TEST_RECORD',
  FMS_WRITE_VEHICLE: 'FMS_WRITE_VEHICLE',
  READ_ORGANIZATION: 'READ_ORGANIZATION', // GROUP
  FMS_PHR_HISTORICAL: 'FMS_PHR_HISTORICAL',
}

export enum DateTypeOptions {
  LastOneHour = 'lastOneHour',
  Today = 'today',
  Yesterday = 'yesterday',
  DateRange = 'dateRange',
}

export const DATE_TYPE_OPTIONS = [
  {
    label: 'Last 1 Hour',
    value: DateTypeOptions.LastOneHour,
  },
  {
    label: 'Today',
    value: DateTypeOptions.Today,
  },
  {
    label: 'Yesterday',
    value: DateTypeOptions.Yesterday,
  },
  {
    label: 'Date Range',
    value: DateTypeOptions.DateRange,
  },
]

export const API_DATE_FORMAT = 'YYYY-MM-DD'

export const API_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const QC_OPTIONS = [
  {
    label: 'All',
    value: QC_FILTER.ALL,
  },
  {
    label: 'Passed',
    value: QC_FILTER.PASS,
  },
  {
    label: 'Failed',
    value: QC_FILTER.FAIL,
  },
]

export const STATUS_OPTIONS = [
  {
    label: 'All',
    value: DEVICES_FILTER_BY.ALL,
  },
  {
    label: 'Assigned',
    value: DEVICES_FILTER_BY.ASSIGNED,
  },
  {
    label: 'Unassigned',
    value: DEVICES_FILTER_BY.UNASSIGNED,
  },
]

export const statusVariantMapping = {
  completed: 'info',
  pending: 'pending',
  'no data available': 'default',
  failed: 'danger',
}

// Status icon mapping
export const iconStatusMapping = {
  [VEHICLE_STATUS.IDLE]: idleIcon,
  [VEHICLE_STATUS.MOVING]: onlineIcon,
  [VEHICLE_STATUS.STOP]: offlineIcon,
  [VEHICLE_STATUS.NA]: offlineIcon,
}

// Mapping vehicle status color variant
export const variantStatusMapping = {
  [VEHICLE_STATUS.IDLE]: 'warning',
  [VEHICLE_STATUS.MOVING]: 'success',
  [VEHICLE_STATUS.STOP]: 'danger',
  [VEHICLE_STATUS.NA]: 'default',
}
export const DEFAULT_ZOOM_RADIUS = 10

export const FLEET_MODEL = {
  ALVA2: 'ALVA2',
}

export const DEVICE_MODEL = {
  CAN: 'TFT100 CAN',
}

export const FLEET_MODEL_DEVICE = {
  'TFT100 RS485': 'ALVA ONE',
  'TFT100 CAN': 'ALVA CERVO',
}

export const centerJakarta = [-6.22678, 106.798186]

export const logoLoginMapping = {
  [DOMAINS.FLEETO_ALVA_DOMAIN as string]: '/public/brands/alva-dark.svg',
  [DOMAINS.FLEETO_MAIN_DOMAIN as string]: '/public/brands/logo-fleeto-v4.svg',
  [SUBDOMAINS.FLEETO_XL_SUBDOMAIN as string]: '/public/brands/xl-axiata.svg',
}

export const REPLAY_CONTROL = {
  PAUSE: 'PAUSE',
  PLAY: 'PLAY',
  SKIP_NEXT: 'SKIP_NEXT',
  SKIP_PREVIOUS: 'SKIP_PREVIOUS',
  SPEED_CONTROL: 'SPEED_CONTROL',
  STOP: 'STOP',
}

export const ICON_ASSETS_MENU = {
  kalista: '/public/images/bus.svg',
  xapiens: '/public/images/car.svg',
}

export const DYNAMIC_ASSET_ICON_MENU = {
  kalista: <ReactSVG src="/public/icons/menu-icon/bus.svg" />,
  xapiens: <ReactSVG src="/public/icons/menu-icon/car.svg" />,
}

export const REMARKS_LABEL = {
  kalista: 'Customer',
  xapiens: 'Group',
}

export const KIDECO_SPEED_POINT = [
  {
    type: 'W',
    latitude: -1.847486299,
    longitude: 115.885596994,
    description: 'Point_Speed 1 21',
  },
  {
    type: 'W',
    latitude: -1.725413084,
    longitude: 115.828412523,
    description: 'Point_Speed 1 1',
  },
  {
    type: 'W',
    latitude: -1.837887704,
    longitude: 115.900486486,
    description: 'Point_Speed 1 2',
  },
  {
    type: 'W',
    latitude: -1.845551136,
    longitude: 115.905748387,
    description: 'Point_Speed 1 3',
  },
  {
    type: 'W',
    latitude: -1.872221517,
    longitude: 115.914844511,
    description: 'Point_Speed 1 4',
  },
  {
    type: 'W',
    latitude: -1.889702786,
    longitude: 115.916021025,
    description: 'Point_Speed 1 5',
  },
  {
    type: 'W',
    latitude: -1.825338699,
    longitude: 116.154772381,
    description: 'Point_Speed 1 6',
  },
  {
    type: 'W',
    latitude: -1.903368554,
    longitude: 115.893321625,
    description: 'Point_Speed 1 7',
  },
  {
    type: 'W',
    latitude: -1.906252081,
    longitude: 115.895087469,
    description: 'Point_Speed 1 8',
  },
  {
    type: 'W',
    latitude: -1.908361716,
    longitude: 115.86134547,
    description: 'Point_Speed 1 9',
  },
  {
    type: 'W',
    latitude: -1.90035568,
    longitude: 115.85130754,
    description: 'Point_Speed 1 10',
  },
  {
    type: 'W',
    latitude: -1.884098803,
    longitude: 115.860596024,
    description: 'Point_Speed 1 11',
  },
  {
    type: 'W',
    latitude: -1.92556851,
    longitude: 115.855248393,
    description: 'Point_Speed 1 12',
  },
  {
    type: 'W',
    latitude: -1.945283451,
    longitude: 115.866712448,
    description: 'Point_Speed 1 13',
  },
  {
    type: 'W',
    latitude: -1.981438003,
    longitude: 115.879136329,
    description: 'Point_Speed 1 14',
  },
  {
    type: 'W',
    latitude: -1.934259805,
    longitude: 115.892406599,
    description: 'Point_Speed 1 15',
  },
  {
    type: 'W',
    latitude: -1.864285011,
    longitude: 115.893334938,
    description: 'Point_Speed 1 16',
  },
  {
    type: 'W',
    latitude: -1.857940779,
    longitude: 115.89198066,
    description: 'Point_Speed 1 17',
  },
  {
    type: 'W',
    latitude: -1.85208648,
    longitude: 115.88953802,
    description: 'Point_Speed 1 18',
  },
  {
    type: 'W',
    latitude: -1.842077411,
    longitude: 115.869991329,
    description: 'Point_Speed 1 19',
  },
  {
    type: 'W',
    latitude: -1.927971202,
    longitude: 115.858548174,
    description: 'Point_Speed 1 20',
  },
  {
    type: 'W',
    latitude: -1.884702893,
    longitude: 115.913972745,
    description: 'Point_Speed 1 22',
  },
  {
    type: 'W',
    latitude: -1.872304,
    longitude: 115.914891,
    description: 'Point_Speed 1 23',
  },
  {
    type: 'W',
    latitude: -1.868878,
    longitude: 115.914251,
    description: 'Point_Speed 1 24',
  },
  {
    type: 'W',
    latitude: -1.870395,
    longitude: 115.91624,
    description: 'Point_Speed 1 25',
  },
  {
    type: 'W',
    latitude: -1.868619,
    longitude: 115.916288,
    description: 'Point_Speed 1 26',
  },
]
