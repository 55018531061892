import React, { memo } from 'react'

import { Box, Drawer, IconButton } from '@material-ui/core'
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded'
import { ReactSVG } from 'react-svg'

import { currentSubdomain, SUBDOMAINS } from '~/config'
import useAuth from '~/utils/auth/useAuth'

import useStyles from './style'

interface Props {
  children: React.ReactNode
  handleDrawer: () => void
  open: boolean
}

const DesktopSidebar = ({ children, handleDrawer, open }: Props) => {
  const classes = useStyles({ open })
  const { userData } = useAuth()

  const orgCode = userData?.userOrg?.orgCode.toLowerCase()

  const LOGO_ORG_CODE = () => ({
    kalista: (
      <img
        className={classes.logoKalista}
        src="/public/brands/logo-kalista.png"
      />
    ),
  })

  // TODO: Experimental logo
  const LOGO_DOMAIN = () => ({
    [SUBDOMAINS.FLEETO_MAIN_SUBDOMAIN]: (
      <ReactSVG
        className={classes.logo}
        src="/public/brands/logo-fleeto-white.svg"
      />
    ),
    [SUBDOMAINS.FLEETO_ALVA_SUBDOMAIN]: (
      <img className={classes.logoAlva} src="/public/brands/alva-logo.webp" />
    ),
    [SUBDOMAINS.FLEETO_XL_SUBDOMAIN]: (
      <img className={classes.logo} src="/public/brands/xl-axiata-blue.svg" />
    ),
  })

  return (
    <Box className="hide-on-mobile">
      <Drawer
        variant="permanent"
        open={open}
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHead}>
          <Box hidden={!open}>
            {LOGO_ORG_CODE()[orgCode] || LOGO_DOMAIN()[currentSubdomain] || (
              <ReactSVG
                className={classes.logo}
                src="/public/brands/logo-fleeto-white.svg"
              />
            )}
          </Box>
          <IconButton className={classes.expandButton} onClick={handleDrawer}>
            {open ? (
              <DoubleArrowRoundedIcon className={classes.iconClose} />
            ) : (
              <DoubleArrowRoundedIcon />
            )}
          </IconButton>
        </div>
        {children}
      </Drawer>
    </Box>
  )
}

export default memo(DesktopSidebar)
