import { CSSProperties } from 'react'

import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

import { colors as importedColors } from './colors'

export const fontSize = {
  10: '0.625rem',
  12: '0.75rem',
  14: '0.875rem',
  16: '1rem',
  18: '1.125rem',
  20: '1.25rem',
  22: '1.375rem',
  24: '1.5rem',
  28: '1.75rem',
  30: '1.875rem',
  32: '2rem',
  34: '2.125rem',
  40: '2.5rem',
  48: '3rem',
  50: '3.125rem',
  64: '4rem',
  72: '4.5rem',
}

interface IFontSizeProp {
  10: CSSProperties['fontSize']
  12: CSSProperties['fontSize']
  14: CSSProperties['fontSize']
  16: CSSProperties['fontSize']
  18: CSSProperties['fontSize']
  20: CSSProperties['fontSize']
  22: CSSProperties['fontSize']
  24: CSSProperties['fontSize']
  28: CSSProperties['fontSize']
  30: CSSProperties['fontSize']
  32: CSSProperties['fontSize']
  34: CSSProperties['fontSize']
  40: CSSProperties['fontSize']
  48: CSSProperties['fontSize']
  50: CSSProperties['fontSize']
  64: CSSProperties['fontSize']
  72: CSSProperties['fontSize']
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    fontSize?: IFontSizeProp
    element?: {
      boxShadow: CSSProperties['boxShadow']
    }
  }
  interface ThemeOptions {
    fontSize?: IFontSizeProp
    element?: {
      boxShadow?: CSSProperties['boxShadow']
    }
  }
}

export const colors = {
  ...importedColors,
}

export const defaultTheme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
      },
    },
  },
  palette: {
    common: {
      black: colors.Black,
      white: colors.White,
    },
    primary: {
      main: colors.GulfBlue,
      contrastText: colors.White,
      light: colors.PigeonPost,
    },
    secondary: {
      main: colors.HavelockBlue,
      contrastText: colors.White,
    },
    error: {
      main: colors.TorchRed,
      contrastText: colors.White,
    },
    text: {
      primary: colors.Black,
      secondary: colors.RiverBed,
      disabled: colors.DustyGrey,
      hint: colors.DustyGrey,
    },
    background: {
      default: colors.Gallery,
    },
    action: {
      disabled: colors.DustyGrey,
    },
    info: {
      main: colors.PaleBlue,
    },
  },
  typography: {
    htmlFontSize: 10,
    fontSize: 10,
    fontFamily: 'Inter, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  fontSize,
  spacing: (factor) =>
    [
      '0.125rem',
      '0.25rem',
      '0.5rem',
      '0.75rem',
      '1rem',
      '1.25rem',
      '1.5rem',
      '1.75rem',
      '2rem',
      '2.25rem',
      '2.5rem',
      '2.75rem',
      '3rem',
    ][factor],
})

export const createDynamicTheme = (orgCode: string) => {
  const orgTheme = {
    fleeto: {
      primary: {
        main: colors.GulfBlue,
        contrastText: colors.White,
        dark: colors.Denim2,
        light: colors.DodgerBlue2,
      },
      secondary: {
        main: colors.HavelockBlue,
        light: colors.Malibu,
        dark: colors.ChathamsBlue,
        contrastText: colors.White,
      },
    },
    kalista: {
      primary: {
        main: colors.Gossamer,
        contrastText: colors.White,
        dark: colors.Lochinvar,
        light: colors.CaribbeanGreen,
      },
      secondary: {
        main: colors.CaribbeanGreen,
        light: colors.Turquoise,
        dark: colors.Lochinvar,
        contrastText: colors.White,
      },
    },
    xl: {
      primary: {
        main: colors.blue[90],
        contrastText: colors.White,
        dark: colors.ChathamsBlue,
        light: colors.DodgerBlue2,
      },
      secondary: {
        main: colors.HavelockBlue,
        light: colors.Malibu,
        dark: colors.ChathamsBlue,
        contrastText: colors.White,
      },
    },
  }

  const secondary = orgTheme[orgCode]?.secondary || orgTheme.fleeto.secondary
  const primary = orgTheme[orgCode]?.primary || orgTheme.fleeto.primary

  return createTheme({
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    palette: {
      common: {
        black: colors.Black,
        white: colors.White,
      },
      primary: primary,
      secondary: secondary,
      error: {
        main: colors.TorchRed,
        contrastText: colors.White,
      },
      text: {
        primary: colors.Black,
        secondary: colors.RiverBed,
        disabled: colors.DustyGrey,
        hint: colors.DustyGrey,
      },
      background: {
        default: colors.Gallery,
      },
      action: {
        disabled: colors.DustyGrey,
      },
      info: {
        main: colors.PaleBlue,
      },
    },
    typography: {
      htmlFontSize: 10,
      fontSize: 10,
      fontFamily: 'Inter, sans-serif',
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    fontSize,
    spacing: (factor) =>
      [
        '0.125rem',
        '0.25rem',
        '0.5rem',
        '0.75rem',
        '1rem',
        '1.25rem',
        '1.5rem',
        '1.75rem',
        '2rem',
        '2.25rem',
        '2.5rem',
        '2.75rem',
        '3rem',
      ][factor],
  })
}

const theme = responsiveFontSizes(createDynamicTheme('fleeto'))

export default theme
