import React, { useMemo, useState } from 'react'

import { responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles'
import { cookies as zxAuthCookies } from '@xti/frontend-kit-auth/auth'

import { currentSubdomain, SUBDOMAINS } from '~/config'
import { LOCAL_STORAGE_KEY_USER } from '~/config/constants'
import { createDynamicTheme } from '~/styles/theme'
import * as Storage from '~/utils/localStorage'

interface AuthContextProps {
  isLoggedIn: boolean
  permissions: any
  userData: any
  setIsLoggedIn: (isLoggin: boolean) => void
  setPermissions: (permissions: any) => void
  setUserData: (userData: any) => void
}

export const AuthContext = React.createContext<Partial<AuthContextProps>>({})

interface AuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(zxAuthCookies.getToken()?.token)
  )
  const [userData, setUserData] = useState(Storage.load(LOCAL_STORAGE_KEY_USER))
  const [permissions, setPermissions] = useState([])

  const orgCode = useMemo(
    () => userData?.userOrg?.orgCode?.toLowerCase() || 'fleeto',
    [userData]
  )

  const theme = useMemo(() => {
    const mappingCode = {
      kalista: 'kalista',
    }
    if (mappingCode[orgCode]) {
      return responsiveFontSizes(createDynamicTheme(orgCode))
    }
    const mappingSubdomain = {
      [SUBDOMAINS.FLEETO_MAIN_SUBDOMAIN]: SUBDOMAINS.FLEETO_MAIN_SUBDOMAIN,
      [SUBDOMAINS.FLEETO_ALVA_SUBDOMAIN]: SUBDOMAINS.FLEETO_ALVA_SUBDOMAIN,
      [SUBDOMAINS.FLEETO_XL_SUBDOMAIN]: SUBDOMAINS.FLEETO_XL_SUBDOMAIN,
    }
    if (mappingSubdomain[currentSubdomain]) {
      return responsiveFontSizes(
        createDynamicTheme(mappingSubdomain[currentSubdomain])
      )
    }
    return responsiveFontSizes(createDynamicTheme(orgCode))
  }, [orgCode])

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        userData,
        setUserData,
        setPermissions,
        permissions,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AuthContext.Provider>
  )
}
